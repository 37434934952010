import { Col, Input, Select } from 'antd';
import FilterName from 'pages/ClientInteractionsPage/components/FilterName';
import RangeInputs from 'components/Inputs/RangeInputs';
import { CUSTOM_FIELDS_TYPES, DATE_PICKER_MODES } from 'core/utils/constants';
import { selectSearch } from 'core/utils/selectSearch';
import { debounce, find, isEmpty, isNil, some, sortBy, uniqBy } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import { actions } from 'redux/lists/clientInteractionsList';
import { getCustomFieldsByIds } from 'redux/selectors/customFields';
import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';
import DurationPicker from 'components/Inputs/DurationPicker';
import CustomDatePicker from 'components/DatePicker/DatePicker';
import { customFieldsResource } from 'redux/resources/customFields';
import Text from 'antd/lib/typography/Text';
import styled from 'styled-components';
import { CategoryName, CategoryNameBlock, FilterContainer, StyledSelect } from './styled';
import QolioAiIcon from '../../../components/QolioAiIcon';

const { Option } = Select;

const AiIconInputStyles = styled.div`
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
`;

const CustomFieldsFilters = ({
  disabled,
  updateCustomFieldFilters,
  customFieldFilters = {},
  customFields = [],
  customFieldsByIds = {},
  loadCustomFields,
  currentPage,
  setCurrentPage,
  totalPages,
  setTotalPages
}) => {
  const { t } = useTranslation();
  const organizationId = useSelector(
    state => state.reduxTokenAuth.currentUser.attributes.user['organization-id']
  );
  const [searchCustomFields, setSearchCustomFields] = useState('');
  const [loadingMore, setLoadingMore] = useState(false);
  const scrollContainerRef = useRef(null);

  const loadMore = async () => {
    if (loadingMore) {
      return;
    }

    if (currentPage === totalPages) {
      return;
    }

    setLoadingMore(true);
    try {
      const response = await loadCustomFields({
        organization_id: organizationId,
        page_number: currentPage + 1,
        page_size: 10
      });

      if (isEmpty(totalPages)) {
        setTotalPages(response?.meta?.totalPages);
      }

      setCurrentPage(prevPage => prevPage + 1);
    } finally {
      setLoadingMore(false);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (scrollContainerRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = scrollContainerRef.current;
        if (scrollTop + clientHeight >= scrollHeight - 5 && !loadingMore) {
          loadMore();
        }
      }
    };

    const currentContainer = scrollContainerRef.current;
    if (currentContainer) {
      currentContainer.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (currentContainer) {
        currentContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, [loadingMore, currentPage]);

  const renderCustomFieldFilter = ({
    id,
    fieldType,
    key,
    name,
    options,
    dependsOnId,
    settings,
    usedForAi
  }) => {
    const renderFilterName = () => {
      return (
        <FilterName>
          {name}
          {usedForAi && (
            <QolioAiIcon styles={{ marginLeft: '5px', width: '18px', height: '18px' }} />
          )}
        </FilterName>
      );
    };

    const getComponent = () => {
      if (fieldType === CUSTOM_FIELDS_TYPES.DURATION) {
        return (
          <>
            {renderFilterName()}
            <DurationPicker
              from={customFieldFilters[key]?.from}
              to={customFieldFilters[key]?.to}
              disabled={disabled}
              size="medium"
              style={{ width: '100%' }}
              allowClear
              onChange={({ from, to }) => {
                updateCustomFieldFilters({
                  [key]: {
                    from: isNil(from) ? undefined : `${from}`,
                    to: isNil(to) ? undefined : `${to}`
                  }
                });
              }}
            />
          </>
        );
      }
      if (fieldType === CUSTOM_FIELDS_TYPES.BOOLEAN) {
        return (
          <>
            {renderFilterName()}
            <StyledSelect
              disabled={disabled}
              showSearch
              allowClear
              optionLabelProp="label"
              placeholder={name}
              value={customFieldFilters[key]?.eq || undefined}
              onChange={value => updateCustomFieldFilters({ [key]: { eq: value } })}
              filterOption={(input, option) =>
                selectSearch({ input, option, searchProp: 'children' })
              }
            >
              {[
                { label: t('general.yes'), value: 'true' },
                { label: t('general.no'), value: 'false' }
              ].map(({ label, value }) => (
                <Option key={label} value={value} label={label}>
                  {label}
                </Option>
              ))}
            </StyledSelect>
          </>
        );
      }

      if (
        fieldType === CUSTOM_FIELDS_TYPES.STRING ||
        (fieldType === CUSTOM_FIELDS_TYPES.STRING_ARRAY && isEmpty(options))
      ) {
        return (
          <div style={{ position: 'relative', display: 'inline-block', width: '100%' }}>
            <Input
              disabled={disabled}
              value={customFieldFilters[key]?.contains || undefined}
              onChange={e =>
                updateCustomFieldFilters({ [key]: { contains: e.target.value } })
              }
              placeholder={name}
            />

            {/* Плашка справа */}
            {usedForAi && (
              <AiIconInputStyles>
                <QolioAiIcon styles={{ width: '16px', height: '16px' }} />
              </AiIconInputStyles>
            )}
          </div>
        );
      }

      if (
        fieldType === CUSTOM_FIELDS_TYPES.NUMBER ||
        fieldType === CUSTOM_FIELDS_TYPES.NUMBER_ARRAY
      ) {
        return (
          <>
            {renderFilterName()}
            <RangeInputs
              disabled={disabled}
              value={customFieldFilters[key]}
              precision={0}
              onChange={({ from, to }) => updateCustomFieldFilters({ [key]: { from, to } })}
            />
          </>
        );
      }
      if (fieldType === CUSTOM_FIELDS_TYPES.DATETIME) {
        return (
          <>
            {renderFilterName()}
            <CustomDatePicker
              onChange={value => updateCustomFieldFilters({ [key]: value })}
              value={customFieldFilters[key] || {}}
              mode={DATE_PICKER_MODES.custom.customFields}
              isClientInteractionPage
              allowClear
              loading={disabled}
              disabled={disabled}
            />
          </>
        );
      }

      if (!isEmpty(settings)) {
        // id, fieldType, key, name, options, dependsOnId, settings
        const parentKey = customFieldsByIds[dependsOnId]?.key;
        const parentSelectedValue = customFieldFilters[parentKey]?.eq;

        // * allow options on parent key or allow all
        const options = parentSelectedValue
          ? settings.filter(({ parentKey }) => parentKey === parentSelectedValue)
          : uniqBy(settings, 'key');

        const setControlledValueToUndefined = () => {
          if (customFieldFilters[key]?.eq) {
            updateCustomFieldFilters({ [key]: { eq: undefined } });
          }

          const childKey = find(customFieldsByIds, field => field.dependsOnId === id)?.key;
          if (childKey && customFieldFilters[childKey]?.eq) {
            updateCustomFieldFilters({ [childKey]: { eq: undefined } });
          }

          return undefined;
        };

        // * if value should be controlled - check if that option exists. if not - set to undefined
        const controlledValue =
          some(options, ({ key: allowedKey }) => allowedKey === customFieldFilters[key]?.eq) ||
          !parentKey
            ? customFieldFilters[key]?.eq
            : undefined;

        if (controlledValue !== customFieldFilters[key]?.eq) {
          setControlledValueToUndefined();
        }

        return (
          <StyledSelect
            disabled={disabled}
            showSearch
            allowClear
            optionLabelProp="label"
            placeholder={name}
            value={
              parentSelectedValue ? controlledValue : customFieldFilters[key]?.eq || undefined
            }
            onChange={value => updateCustomFieldFilters({ [key]: { eq: value } })}
            filterOption={(input, option) =>
              selectSearch({ input, option, searchProp: 'children' })
            }
          >
            {sortBy(options, ['text']).map(({ key, text }) => (
              <Option key={key} value={key} label={text}>
                {text}
              </Option>
            ))}
          </StyledSelect>
        );
      }

      if (!isEmpty(options)) {
        const compareType = fieldType === CUSTOM_FIELDS_TYPES.STRING_ARRAY ? 'contains' : 'eq';
        return (
          <StyledSelect
            disabled={disabled}
            showSearch
            allowClear
            optionLabelProp="label"
            placeholder={name}
            value={customFieldFilters[key]?.[compareType] || undefined}
            onChange={value => updateCustomFieldFilters({ [key]: { [compareType]: value } })}
            filterOption={(input, option) =>
              selectSearch({ input, option, searchProp: 'children' })
            }
          >
            {options.map(option => (
              <Option key={option} value={option} label={option}>
                {option}
              </Option>
            ))}
          </StyledSelect>
        );
      }
    };

    return <FilterContainer key={id}>{getComponent()}</FilterContainer>;
  };

  const { otherCustomFields, amoCustomFields } = customFields.reduce(
    (acc, field) => {
      if (field?.entityType) {
        return { ...acc, amoCustomFields: [...acc.amoCustomFields, field] };
      }

      return { ...acc, otherCustomFields: [...acc.otherCustomFields, field] };
    },
    { otherCustomFields: [], amoCustomFields: [] }
  );

  const handleSearchCustomFields = debounce(search => {
    loadCustomFields({
      organization_id: organizationId,
      page_number: 1,
      page_size: 10,
      search_input: search
    });
  }, 350);

  const handleSearchChangeCustomFields = e => {
    const searchValue = e.target.value && e.target.value.toLowerCase();
    setSearchCustomFields(searchValue);
    handleSearchCustomFields(searchValue);
  };

  const sortOrderCustomFields = {
    [CUSTOM_FIELDS_TYPES.NUMBER]: 1,
    [CUSTOM_FIELDS_TYPES.NUMBER_ARRAY]: 2,
    [CUSTOM_FIELDS_TYPES.BOOLEAN]: 3,
    [CUSTOM_FIELDS_TYPES.DATETIME]: 4,
    [CUSTOM_FIELDS_TYPES.DURATION]: 5,
    [CUSTOM_FIELDS_TYPES.STRING_URL]: 6,
    [CUSTOM_FIELDS_TYPES.STRING]: 7,
    [CUSTOM_FIELDS_TYPES.STRING_ARRAY]: 8
  };

  const combinedFields = [...otherCustomFields, ...amoCustomFields];

  const getSortWeightCustomFields = type => sortOrderCustomFields[type] || 10;

  return (
    <SRow gutter={[0, 16]} style={{ marginTop: '8px', marginBottom: '-8px' }}>
      <SCol>
        <SRow>
          <Col span={24}>
            <CategoryNameBlock type="flex" align="middle" justify="space-between">
              <Col span={24}>
                <CategoryName>
                  {t('clientInteractionsPage.tableFilters.tableCategoryName')}
                </CategoryName>
              </Col>
            </CategoryNameBlock>
          </Col>
          <Col span={24}>
            <Input
              placeholder={t('clientInteractionsPage.tableFilters.search.search')}
              value={searchCustomFields}
              onChange={handleSearchChangeCustomFields}
            />
          </Col>
          <div
            style={{ marginTop: '10px', overflowY: 'auto', maxHeight: '300px', width: '100%' }}
            ref={scrollContainerRef}
          >
            <Col span={24}>
              {combinedFields
                .filter(field => field.name.toLowerCase().includes(searchCustomFields))
                .sort((a, b) => {
                  const weightA = getSortWeightCustomFields(a.fieldType);
                  const weightB = getSortWeightCustomFields(b.fieldType);

                  if (weightA < weightB) {
                    return -1;
                  }
                  if (weightA > weightB) {
                    return 1;
                  }

                  return 0;
                })
                .map(renderCustomFieldFilter)}
            </Col>
            <Col span={24}>{currentPage === totalPages && <Text>Все поля загружены</Text>}</Col>
          </div>
        </SRow>
      </SCol>
    </SRow>
  );
};

const mapStateToProps = (state, ownProps) => {
  const customFieldsByIds = getCustomFieldsByIds(state, true);
  const { customFieldFilters, loading } = state.clientInteractionsList;
  const { tableLoading, tableId } = state.uiClientInteractions;

  return {
    disabled:
      tableLoading ||
      loading ||
      isEmpty(tableId) ||
      ownProps?.selectedWorkPlanTaskConfigurationId ||
      ownProps?.hasConflicts,
    customFields: Object.values(customFieldsByIds)
      .filter(customField => customField?.usedForFilters)
      .sort((a, b) => {
        if (a.id === b?.dependsOnId) {
          return -1;
        }

        if (b.id === a?.dependsOnId) {
          return 1;
        }

        return 0;
      }),
    customFieldFilters: ownProps?.hasConflicts ? {} : customFieldFilters,
    customFieldsByIds
  };
};

const mapDispatchToProps = {
  updateCustomFieldFilters: actions.updateCustomFieldFilters,
  loadCustomFields: customFieldsResource.operations.load
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomFieldsFilters);
