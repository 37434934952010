import React, { useEffect, useRef, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, message, Modal, Tooltip, Upload } from 'antd';
import {
  callUploadEndpointV3,
  getImportExampleFileEndpoint,
  importFromFileEndpoint
} from 'core/api';
import { processJsonApiObject } from 'core/jsonapi';
import axios from 'axios';
import { operations } from 'redux/lists/uploadedCallsListReducerV3';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import Text from 'antd/lib/typography/Text';
import fileDownload from 'js-file-download';
import { useSelections } from 'ahooks';
import { cancelEditingCalls } from 'redux/ui/uploadCallsPage/reducer';
import { phoneCallsResourceV3 } from 'redux/resources/callsV3';
import moment from 'moment/moment';
import { usersResource } from 'redux/resources/users';
import SCard from './components/SCard';
import UploadedCallsTable from './UploadedCallsTable/UploadedCallsTable';
import {
  HeaderButtonsContainer,
  HeaderFileList,
  HeaderItem,
  HeaderTitle,
  HeaderUploadCallsPageContainer,
  TableContainer,
  UploadCallsPageContainer
} from './styled';

const maxSize = 30 * 1024 * 1024; // 30 MB

const UploadCallsPage = ({
  onUploadCall,
  loadUsers,
  loadCalls,
  listPage,
  cancelEditing,
  calls,
  deleteCall,
  updateCalls
}) => {
  const { t } = useTranslation();
  const getOrganizationId = useSelector(
    state => state.reduxTokenAuth.currentUser.attributes.user['organization-id']
  );
  const currentTimeZone = moment().format('Z');
  const [filesList, setFilesList] = useState([]);
  const [visible, setVisible] = useState(false);
  const [contentModal, setContentModal] = useState(null);
  const [disabledSaveBtn, setDisabledSaveBtn] = useState(true);
  const [massActionOperatorId, setMassActionOperatorId] = useState(null);
  const [massActionDirection, setMassActionDirection] = useState(null);
  const ids = useSelector(state => state.uploadedCallsListV3.ids);
  const uploadRef = useRef();

  const { allSelected, selected, isSelected, toggle, toggleAll, unSelectAll } = useSelections(
    ids,
    []
  );

  const { callsByIds, massAction } = useSelector(state => state.uiUploadCallsPage);

  const handleBeforeUpload = file => {
    const fileExt = file.name
      .split('.')
      .pop()
      .toLowerCase();

    if (fileExt !== 'mp3' && fileExt !== 'wav' && fileExt !== 'csv') {
      message.error(
        `${t('uploadCallsPage.messages.fileFormatText')} '${file.name}' ${t(
          'uploadCallsPage.messages.fileFormatMessage'
        )}`,
        5
      );
      if (file.size >= maxSize) {
        message.error(
          `${t('uploadCallsPage.messages.maxFileText')} '${file.name}' ${t(
            'uploadCallsPage.messages.maxFileSize'
          )}`,
          5
        );
        return false;
      }
      return false;
    }
    if (file.size >= maxSize) {
      message.error(
        `${t('uploadCallsPage.messages.maxFileText')} '${file.name}' ${t(
          'uploadCallsPage.messages.maxFileSize'
        )}`,
        5
      );
      return false;
    }
    return true;
  };

  const handleChange = info => {
    const { fileList } = info;
    const validFilesList = [];

    fileList.forEach(file => {
      const fileExt = file.name
        .split('.')
        .pop()
        .toLowerCase();

      if (file.size <= maxSize) {
        if (fileExt === 'mp3' || fileExt === 'wav' || fileExt === 'csv') {
          validFilesList.push(file);
        }
      }
    });

    setFilesList(validFilesList);
  };

  const downloadFile = async () => {
    await fetch(getImportExampleFileEndpoint, { method: 'GET' })
      .then(response => response.text())
      .then(data => {
        fileDownload(data, 'example.csv');
      })
      .catch(error => {
        console.log(error);
        message.error(t('errorPages.internalServerError.title'));
      });
  };

  const customRequest = async ({ file, onSuccess, onError, onProgress }) => {
    const fileExtension = file.name.split('.').pop();
    const headers = {
      'access-token': localStorage.getItem('access-token'),
      client: localStorage.getItem('client'),
      uid: localStorage.getItem('uid'),
      'content-type': 'multipart/form-data',
      'x-organization-id': localStorage.getItem('organizationId')
    };

    const onUploadProgress = ({ total, loaded }) => {
      const percent = Math.round((loaded * 100) / total);
      onProgress({ percent });
    };
    const currentDate = new Date();
    const utcDate = currentDate.toISOString().split('.')[0] + 'Z';

    const body = new FormData();
    body.append(fileExtension === 'csv' ? 'content' : 'call[]', file, file.name);
    body.append('organization_id', getOrganizationId);
    body.append('attributes[]', [
      {
        operatorId: null,
        direction: null,
        started_at: utcDate,
        client_phone_number: null
      }
    ]);
    // body.append(fileExtension === 'csv' ? 'content' : 'record', file);
    try {
      const response = await axios.post(
        fileExtension === 'csv' ? importFromFileEndpoint : callUploadEndpointV3,
        // fileExtension === 'csv' ? importFromFileEndpoint : callUploadEndpoint,
        body,
        { headers, onUploadProgress }
      );
      fileExtension === 'csv'
        ? setVisible(true)
        : message.success(`${file.name} ${t('uploadCallsPage.messages.successfullyLoaded')}`);
      // Modal success import
      setContentModal(
        <Text>
          <p>{t('clientInteractionsPage.importModal.content')}</p>
        </Text>
      );
      const call = fileExtension === 'csv' ? '' : processJsonApiObject(response.data.data);
      onSuccess(response.data);
      onUploadCall(call);
      loadCalls({
        page_size: listPage.size,
        page_number: listPage.number,
        organization_id: getOrganizationId,
        timezone: currentTimeZone
      });
      loadUsers({ include: 'unit,role', pagination: 'false', sort: 'last_name' });
    } catch (error) {
      onError(error);
      console.log(error);
      if (error.message.includes('404')) {
        message.error(t('errorPages.internalServerError.title'));
        return;
      }
      const responseErrors = [];
      if (error.response && fileExtension === 'csv') {
        setVisible(true);
        if (error.response.data.errors) {
          responseErrors.push(...error.response.data.errors);
        } else {
          responseErrors.push(error.response.data.error);
        }
      }
      // При загрузке файла csv если произошла ошибка, то записываем ошибку и выводим в модальном окне
      if (fileExtension === 'csv') {
        setContentModal(
          <Text>
            <p style={{ color: 'red' }}>
              {`${file.name} - ${t('clientInteractionsPage.importModal.errors.title')}`}
            </p>
            {responseErrors &&
              responseErrors.map(error => (
                <>
                  {error}
                  <br />
                </>
              ))}
          </Text>
        );
      } else {
        message.error(`${t('uploadCallsPage.messages.loadFailed')} ${file.name}`);
      }
    }
  };

  const updatedCallsByIds = {};

  useEffect(() => {
    for (const key in callsByIds) {
      updatedCallsByIds[key] = { ...callsByIds[key] };
      if (callsByIds[key].hasOwnProperty('clientPhoneNumber')) {
        const clientPhoneNumber = callsByIds[key].clientPhoneNumber.trim();
        updatedCallsByIds[key].clientPhoneNumber =
          clientPhoneNumber === '' ? 'null' : clientPhoneNumber;
      }
    }
  }, [callsByIds]);

  useEffect(() => {
    let isAllFieldsFilled = true;

    Object.keys(callsByIds).forEach(callId => {
      const findCall = calls.find(call => call.id === callId);
      if (!findCall) return;
      const callUi = updatedCallsByIds[callId];
      const { operatorId, direction, startedAt, clientPhoneNumber } = findCall;

      const checkField = (field, uiField) => {
        if (!field) {
          return uiField && uiField !== 'null';
        }
        return !(uiField && uiField === 'null');
      };

      const checkOperatorId = checkField(operatorId, callUi.operatorId);
      const checkDirection = checkField(direction, callUi.direction);
      const checkStartedAt = checkField(startedAt, callUi.startedAt);
      const checkClientPhoneNumber = checkField(clientPhoneNumber, callUi.clientPhoneNumber);

      if (!checkOperatorId || !checkDirection || !checkStartedAt || !checkClientPhoneNumber) {
        isAllFieldsFilled = false;
      }
    });

    if (Object.values(callsByIds).length === 0) {
      return setDisabledSaveBtn(isAllFieldsFilled);
    }
    setDisabledSaveBtn(!isAllFieldsFilled);
  }, [callsByIds]);

  const handleCancelEditing = () => {
    setMassActionDirection(null);
    setMassActionOperatorId(null);
    unSelectAll();
    cancelEditing();
  };

  const handleSaveCalls = async () => {
    const updatedCallsByIds = {};
    // Убираем лишнии пробелы в значениях у ключей clientPhoneNumber
    for (const key in callsByIds) {
      updatedCallsByIds[key] = { ...callsByIds[key] };
      if (callsByIds[key].hasOwnProperty('clientPhoneNumber')) {
        updatedCallsByIds[key].clientPhoneNumber = callsByIds[key].clientPhoneNumber.trim();
      }
    }
    await updateCalls(updatedCallsByIds);
    await loadCalls({
      page_size: listPage.size,
      page_number: listPage.number,
      organization_id: getOrganizationId,
      timezone: currentTimeZone
    });
    loadUsers({ include: 'unit,role', pagination: 'false', sort: 'last_name' });
    message.success(t('uploadCallsPage.messages.editSuccess'));
    handleCancelEditing(); // При сохранении делаем сброс
  };

  const handleDelete = () => {
    return Modal.confirm({
      centered: true,
      maskClosable: true,
      title: t('uploadCallsPage.table.confirmDelete.title'),
      content: t('uploadCallsPage.table.confirmDelete.content'),
      okText: t('uploadCallsPage.table.confirmDelete.ok'),
      cancelText: t('uploadCallsPage.table.confirmDelete.cancel'),
      onOk: async () => {
        await deleteCall({ delete: selected }); // Удаление звонка(-ов)
        message.success(t('uploadCallsPage.messages.deleteSuccess'));
        Modal.destroyAll(); // Закрываем модальное окно
        unSelectAll(); // Снимаем все прочие выделения чекбоксов
        // Заного подгружаем звонки для обновления пагинации
        await loadCalls({
          page_size: listPage.size,
          page_number: listPage.number,
          organization_id: getOrganizationId,
          timezone: currentTimeZone
        });
        loadUsers({ include: 'unit,role', pagination: 'false', sort: 'last_name' });
      }
    });
  };

  // Автоматически список загруженных файлов при использовании upload из antd находится под кнопкой загрузки, данный хук подставляет список загруженных вайлов в подготовлен блок верстки
  useEffect(() => {
    const listContainer = document.querySelector('.new-upload-list');
    const uploadList = document.querySelector('.ant-upload-list.ant-upload-list-text');

    if (listContainer && uploadList) {
      listContainer.appendChild(uploadList);
    }
  }, []);

  return (
    <UploadCallsPageContainer>
      <Helmet>
        <title>{t('pagesMeta.uploadCallsPage.title')}</title>
      </Helmet>
      <SCard rounded={false} span={24}>
        <HeaderUploadCallsPageContainer>
          <HeaderTitle>{t('uploadCallsPage.messages.fileFormat')}</HeaderTitle>
          <HeaderButtonsContainer>
            {Object.values(callsByIds).length !== 0 && (
              <HeaderItem>
                <Button
                  type="primary"
                  onClick={() => handleCancelEditing()}
                  style={{ marginRight: 8 }}
                >
                  {t('uploadCallsPage.buttons.cancelEditing')}
                </Button>
              </HeaderItem>
            )}
            <HeaderItem>
              <Tooltip
                placement="bottom"
                title={
                  disabledSaveBtn && Object.values(callsByIds).length !== 0
                    ? t('uploadCallsPage.messages.saveEditingTooltip')
                    : ''
                }
              >
                <Button
                  type="primary"
                  disabled={disabledSaveBtn}
                  onClick={() => handleSaveCalls()}
                  style={{ marginRight: 8 }}
                >
                  {t('uploadCallsPage.buttons.save')}
                </Button>
              </Tooltip>
            </HeaderItem>
            <HeaderItem>
              <Button
                type="danger"
                disabled={selected.length === 0}
                onClick={() => handleDelete()}
                style={{ marginRight: 8 }}
              >
                {t('uploadCallsPage.buttons.delete')}
              </Button>
            </HeaderItem>
            <HeaderItem>
              <Button onClick={downloadFile} type="primary">
                {t('uploadCallsPage.buttons.downloadExample')}
              </Button>
            </HeaderItem>
            <HeaderItem>
              <Upload
                ref={uploadRef}
                showUploadList={{ showDownloadIcon: false, showRemoveIcon: false }}
                multiple
                onChange={handleChange}
                beforeUpload={handleBeforeUpload}
                customRequest={customRequest}
                fileList={filesList}
                name="file"
                accept=".mp3,.wav,.csv"
              >
                <Button type="primary">{t('uploadCallsPage.buttons.loadCalls')}</Button>
              </Upload>
            </HeaderItem>
          </HeaderButtonsContainer>
        </HeaderUploadCallsPageContainer>
        {/* Список загруженных файлов, тут автоматически отрисовываются загруженные файлы из компонента Upload */}
        <HeaderFileList
          style={{ width: '100%', maxHeight: '200px', overflowY: 'auto', padding: '0 10px' }}
          className="new-upload-list"
        />
      </SCard>
      {/* Модальное окно для отображения ошибок при загрузке csv файлов */}
      <Modal
        title={t('clientInteractionsPage.importModal.title')}
        visible={visible}
        cancelButtonProps={{ style: { display: 'none' } }} // hide cancel button
        onCancel={() => setVisible(false)}
        onOk={() => setVisible(false)}
      >
        {contentModal}
      </Modal>
      <TableContainer>
        <UploadedCallsTable
          callsByIds={callsByIds}
          massAction={massAction}
          toggleAll={toggleAll}
          toggle={toggle}
          isSelected={isSelected}
          selected={selected}
          allSelected={allSelected}
          massActionOperatorId={massActionOperatorId}
          setMassActionOperatorId={setMassActionOperatorId}
          massActionDirection={massActionDirection}
          setMassActionDirection={setMassActionDirection}
          unSelectAll={unSelectAll}
          getOrganizationId={getOrganizationId}
          handleCancelEditing={handleCancelEditing}
        />
      </TableContainer>
    </UploadCallsPageContainer>
  );
};

const mapStateToProps = (state, ownProps) => {
  const calls = state.uploadedCallsListV3.ids
    .map(id => state.phoneCallsResourceV3.byIds[id])
    .filter(call => !!call);

  return {
    calls,
    users: Object.values(state.usersResource.byIds),
    listPage: state.uploadedCallsListV3.page,
    ...ownProps
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUploadCall: callData => dispatch(phoneCallsResourceV3.actions.loadByIdSucceed(callData)),
    loadUsers: params => dispatch(usersResource.operations.load(params)),
    loadCalls: params => dispatch(operations.load(params)),
    deleteCall: params => dispatch(phoneCallsResourceV3.operations.deleteById(params)),
    updateCalls: params => dispatch(phoneCallsResourceV3.operations.updateById(params)),
    cancelEditing: () => dispatch(cancelEditingCalls())
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UploadCallsPage));
