import { Checkbox, Col, message, Row, Tooltip, Typography } from 'antd';
import STable from 'components/Standard/STable';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SCard from 'components/Standard/SCard';
import SText from 'components/Standard/SText';
import { SCol, SRow } from 'components/Standard';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { actions, operations } from 'redux/lists/aiSettingsList';
import { getLogsAiTask } from 'redux/entities/exports/operations';
import moment from 'moment';
import { operations as promptsListOperations } from 'redux/lists/promptsList';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { CopyOutlined, FileSearchOutlined } from '@ant-design/icons';
import { usersResource } from 'redux/resources/users';
import { aiSettingsResource } from 'redux/resources/aiSettings';
import { salePipelinesResource } from 'redux/resources/salesPipelines';
import { checklistDefinitionsResource } from 'redux/resources/checklistDefinitions';
import CheckedInfo from './CheckedInfo';
import ModalChecklistDefinitions from './ModalChecklistDefinitions';
import ModalTask from './ModalTask';
import ModalTaskAnalyticsError from './ModalTaskAnalyticsError';

const AiSettingsTable = ({
  isModalOpenAddorEditTask,
  setIsModalOpenAddorEditTask,
  isEditingData,
  setIsEditingData,
  updatePage,
  customFields,
  setSorting,
  loading,
  totalCount,
  page,
  sort,
  search,
  loadAiSettings,
  status,
  loadPrompts,
  loadUsers,
  loadSalePipelines,
  newTaskModal,
  setNewTaskModal,
  loadChecklistDefinitions
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const organizationId = useSelector(
    state => state.reduxTokenAuth.currentUser.attributes.user['organization-id']
  );
  const currentTimeZone = moment().format('Z');
  const [selectedRows, setSelectedRows] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenAnalyticsError, setIsModalOpenAnalyticsError] = useState(false);
  const [statusChecklistDefinitions, setStatusChecklistDefinitions] = useState(null);
  const [idChecklistDefinitions, setIdChecklistDefinitions] = useState(null);
  const [editingData, setEditingData] = useState({});

  useEffect(() => {
    loadAiSettings({
      organization_id: organizationId,
      page_number: page.number,
      page_size: page.size,
      sort,
      search_input: search,
      task_status: status,
      timezone: currentTimeZone
    });
    loadPrompts({
      organization_id: organizationId,
      pagination: false
    });
    loadUsers({
      pagination: 'false',
      include: 'unit,role.levels',
      sort: 'last_name'
    });
    loadSalePipelines({ pagination: 'false', include: 'statuses' });
    loadChecklistDefinitions({
      pagination: false,
      sort: 'created_at',
      filters: { status: 'published' }
    });
  }, [sort, search, page]);

  const showModal = (status, id) => {
    setStatusChecklistDefinitions(status);
    setIsModalOpen(true);
    setIdChecklistDefinitions(id);
  };
  const showModalAnalyticsError = id => {
    setIsModalOpenAnalyticsError(true);
    setIdChecklistDefinitions(id);
  };
  const handleCheckboxChange = useCallback((e, record) => {
    const { checked } = e.target;
    setSelectedRows(prev =>
      checked ? [...prev, record] : prev.filter(row => row.id !== record.id)
    );
  }, []);

  const handleAllCheckboxChange = e => {
    const { checked } = e.target;
    if (checked) {
      setSelectedRows(customFields);
    } else {
      setSelectedRows([]);
    }
  };
  const onPaginationChange = useCallback((pagination, filters, sorter) => {
    const { pageSize, current } = pagination;
    updatePage({
      size: pageSize.toString(),
      number: current.toString()
    });
    dispatch(setSorting(sorter));
  }, []);
  const onDeleteTask = () => {
    selectedRows.map(selected => {
      dispatch(
        aiSettingsResource.operations.deleteById({
          id: selected.id
        })
      ).then(response => {
        if (response?.id) {
          message.success(t('aiSettingsPage.operations.operationSuccessfully'));
        }
      });
    });
    setSelectedRows([]);
  };
  const handleEditDataModal = record => {
    setEditingData({ ...record });
    setIsEditingData(true);
    setIsModalOpenAddorEditTask(true);
  };

  const handleDownloadLogs = async taskId => {
    if (!taskId) {
      message.error(t('errorPages.internalServerError.title'));
      return;
    }
    await getLogsAiTask({ taskId });
  };

  const restartTask = async (id, taskType) => {
    try {
      if (taskType === 'disposable') {
        await dispatch(aiSettingsResource.operations.startAiSettings({ id })).then(() => {
          message.success(t('aiSettingsPage.operations.operationSuccessfully'));
          message.info(t('messages.success.transcriptionUnsupportedCallLength'), 8);
        });
      } else {
        await dispatch(aiSettingsResource.operations.restoreAiSettings({ id })).then(() => {
          message.success(t('aiSettingsPage.operations.operationSuccessfully'));
          message.info(t('messages.success.transcriptionUnsupportedCallLength'), 8);
        });
      }
      setSelectedRows([]);
      loadAiSettings({
        organization_id: organizationId,
        page_number: page.number,
        page_size: page.size,
        sort,
        search_input: search,
        task_status: status
      });
    } catch (error) {
      message.error(t('aiSettingsPage.operations.operationError'));
    }
  };
  const onStopTask = async id => {
    try {
      await dispatch(aiSettingsResource.operations.stopAiSettings({ id })).then(() => {
        message.success(t('aiSettingsPage.operations.operationSuccessfully'));
      });
      setSelectedRows([]);
      loadAiSettings({
        organization_id: organizationId,
        page_number: page.number,
        page_size: page.size,
        sort,
        search_input: search,
        task_status: status
      });
    } catch (error) {
      message.error(t('aiSettingsPage.operations.operationError'));
    }
  };
  const tableConfig = {
    columns: [
      {
        title: <Checkbox checked={selectedRows.length > 0} onChange={handleAllCheckboxChange} />,
        dataIndex: 'checked',
        key: 'checked',
        fixed: 'left',
        width: 30,
        render: (checked, record) => (
          <Checkbox
            checked={selectedRows.some(row => row.id === record.id)}
            onChange={e => handleCheckboxChange(e, record)}
          />
        )
      },
      {
        title:
          selectedRows.length > 1 ? (
            <SText style={{ position: 'absolute', top: '3px' }}>
              <CheckedInfo onDeleteTask={onDeleteTask} />
            </SText>
          ) : (
            t('general.title')
          ),
        dataIndex: 'name',
        key: 'name',
        fixed: 'left',
        width: 270,
        render: (name, record) => {
          const taskStatus = record?.taskStatus;
          const taskType = record?.taskType;

          const needRestart = () => {
            if (taskStatus === 'validation_error') {
              return false;
            }

            if (
              taskStatus === 'stopped' ||
              (taskType === 'disposable' && taskStatus === 'ready') ||
              (taskType === 'disposable' && taskStatus !== 'process') ||
              (taskType === 'disposable' && taskStatus === 'finish')
            ) {
              return true;
            }

            return false;
          };

          return (
            <Tooltip title={name} placement="topLeft">
              <SRow direction="vertical">
                <SText
                  title={name}
                  onClick={() =>
                    record.taskType !== 'immediate' ? handleEditDataModal(record) : null
                  }
                >
                  <Typography.Link href="#">{name}</Typography.Link>
                </SText>
                <SText style={{ position: 'absolute', top: '3px' }}>
                  {selectedRows.some(row => row.id === record.id) && selectedRows.length < 2 && (
                    <CheckedInfo
                      onDeleteTask={onDeleteTask}
                      isRestart={needRestart()}
                      restartAiTask={() => restartTask(record.id, record?.taskType)}
                      isProcessing={
                        taskStatus === 'process' ||
                        (taskStatus === 'ready' && taskType === 'constant')
                      }
                      stopAiTask={() => onStopTask(record.id)}
                      record={record}
                      taskType={taskType}
                    />
                  )}
                </SText>
              </SRow>
            </Tooltip>
          );
        }
      },
      {
        title: t('aiSettingsPage.tableColumnsTitle.creationDate'),
        dataIndex: 'createdAt',
        key: 'createdAt',
        width: 200,
        align: 'center',
        sorter: true,
        render: createdAt => {
          const data = moment(createdAt).format('DD.MM.YYYY, HH:mm:ss');
          return (
            <Tooltip title={data}>
              <SText>{data}</SText>
            </Tooltip>
          );
        }
      },
      {
        title: t('aiSettingsPage.tableColumnsTitle.lastLaunchDate'),
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        width: 200,
        align: 'center',
        sorter: true,
        render: updatedAt => {
          const data = moment(updatedAt).format('DD.MM.YYYY, HH:mm:ss');
          return (
            <Tooltip title={data}>
              <SText>{data}</SText>
            </Tooltip>
          );
        }
      },
      {
        title: t('aiSettingsPage.tableColumnsTitle.type'),
        key: 'taskType',
        dataIndex: 'taskType',
        width: 100,
        align: 'center',
        ellipsis: {
          showTitle: false
        },
        render: taskType => {
          let title = '';
          switch (taskType) {
            case 'constant':
              title = t('aiSettingsPage.taskSettings.regular');
              break;
            case 'disposable':
              title = t('aiSettingsPage.taskSettings.oneTime');
              break;
            case 'immediate':
              title = t('aiSettingsPage.taskSettings.immediate');
              break;
            default:
              title = t('aiSettingsPage.taskSettings.regular');
          }
          return (
            <Tooltip title={title}>
              <SText>{title}</SText>
            </Tooltip>
          );
        }
      },
      {
        title: t('aiSettingsPage.tableColumnsTitle.status'),
        dataIndex: 'taskStatus',
        key: 'status',
        width: 200,
        align: 'center',
        sorter: true,
        render: (taskStatus, record) => {
          let color;
          let name;

          switch (taskStatus) {
            case 'finish':
              color = '#47AD03';
              switch (record.taskType) {
                case 'disposable':
                  name = t('aiSettingsPage.taskStatus.completed');
                  break;
                case 'constant':
                  name = t('aiSettingsPage.taskStatus.readyToLaunch');
                  break;
                case 'immediate':
                  name = t('aiSettingsPage.taskStatus.completed');
                  break;
                default:
                  name = t('aiSettingsPage.taskStatus.completed');
              }
              break;
            case 'process':
              color = '#ecb900';
              name = t('aiSettingsPage.taskStatus.inProcess');
              break;
            case 'validation_error':
              color = '#E53942';
              name = t('aiSettingsPage.taskStatus.validationError');
              break;
            default:
              color = '#47AD03';
              name = t('aiSettingsPage.taskStatus.readyToLaunch');
          }

          return (
            <Tooltip title={name}>
              <SText style={{ color }}>{name}</SText>
            </Tooltip>
          );
        }
      },
      {
        title: t('aiSettingsPage.tableColumnsTitle.checks'),
        dataIndex: 'analytics',
        key: 'checked',
        width: 100,
        align: 'center',
        render: (analytics, record) => {
          let total = null;
          if (analytics) {
            total = Object.values(JSON.parse(analytics)).reduce((a, b) => a + b, 0);
          }
          return (
            <Tooltip style={{ cursor: 'pointer' }}>
              <SText
                style={{ textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() => showModal('all_statuses', record.id)}
              >
                {total}
              </SText>
            </Tooltip>
          );
        }
      },
      {
        title: t('aiSettingsPage.tableColumnsTitle.inProgress'),
        dataIndex: 'analytics',
        key: 'progress',
        width: 100,
        align: 'center',
        render: (analytics, record) => {
          let errorData = null;
          let resultData = null;
          if (analytics) {
            errorData = JSON.parse(analytics);
            resultData =
              (errorData?.in_analyze || 0) +
              (errorData?.in_transcription || 0) +
              (errorData?.raw || 0);
          }

          return (
            <Tooltip
              title={
                <Row style={{ flexDirection: 'column' }}>
                  <Col>
                    <Typography.Text style={{ color: 'white' }}>
                      {t('aiSettingsPage.taskStatus.inQueue')}
                      {' - '}
                    </Typography.Text>
                    <Typography.Text
                      type="warning"
                      style={{ cursor: 'pointer' }}
                      onClick={() => showModal('raw', record.id)}
                    >
                      {errorData?.raw || 0}
                    </Typography.Text>
                  </Col>

                  <Col>
                    <Typography.Text style={{ color: 'white' }}>
                      {t('aiSettingsPage.taskStatus.transcription')}
                      {' - '}
                    </Typography.Text>
                    <Typography.Text
                      style={{ color: '#597ef7', cursor: 'pointer' }}
                      onClick={() => showModal('in_transcription', record.id)}
                    >
                      {errorData?.in_transcription || 0}
                    </Typography.Text>
                  </Col>

                  <Col>
                    <Typography.Text style={{ color: 'white' }}>
                      {t('aiSettingsPage.taskStatus.analysis')}
                      {' - '}
                    </Typography.Text>
                    <Typography.Text
                      type="success"
                      style={{ cursor: 'pointer' }}
                      onClick={() => showModal('in_analyze', record.id)}
                    >
                      {errorData?.in_analyze || 0}
                    </Typography.Text>
                  </Col>
                </Row>
              }
            >
              <SText style={{ color: '#FFD188', cursor: 'pointer' }}>{resultData}</SText>
            </Tooltip>
          );
        }
      },
      {
        title: t('aiSettingsPage.tableColumnsTitle.successful'),
        dataIndex: 'analytics',
        key: 'ready',
        width: 100,
        align: 'center',
        render: (analytics, record) => {
          let errorData = null;
          if (analytics) {
            errorData = JSON.parse(analytics).analyzed || 0;
          }
          return (
            <Tooltip style={{ cursor: 'pointer' }}>
              <SText
                style={{ color: '#47AD03', cursor: 'pointer' }}
                onClick={() => showModal('analyzed', record.id)}
              >
                {errorData}
              </SText>
            </Tooltip>
          );
        }
      },
      {
        title: t('aiSettingsPage.tableColumnsTitle.withError'),
        dataIndex: 'analytics',
        key: 'error',
        width: 100,
        align: 'center',
        render: (analytics, record) => {
          let errorData = null;
          let resultErrorData = null;
          if (analytics) {
            errorData = JSON.parse(analytics);
            resultErrorData =
              (errorData?.analyze_error || 0) +
              (errorData?.validation_error || 0) +
              (errorData?.transcription_error || 0) +
              (errorData?.analyze_limit_error || 0) +
              (errorData?.transcribation_limit_error || 0);
          }

          return (
            <Tooltip
              overlayStyle={{ maxWidth: 270 }}
              title={
                <Row>
                  {/* Ошибка валидации */}
                  <Col>
                    <Typography.Text style={{ color: 'white' }}>
                      {t('aiSettingsPage.taskStatus.validationError')}
                      {' - '}
                    </Typography.Text>
                    <Typography.Text
                      style={{ color: '#E53942', cursor: 'pointer' }}
                      onClick={() => showModal('validation_error', record.id)}
                    >
                      <Tooltip title={t('aiSettingsPage.tooltips.validationError')}>
                        {errorData?.validation_error || 0}
                      </Tooltip>
                    </Typography.Text>
                  </Col>
                  {/* Ошибка анализа */}
                  <Col>
                    <Typography.Text style={{ color: 'white' }}>
                      {t('aiSettingsPage.taskStatus.analysisError')}
                      {' - '}
                    </Typography.Text>
                    <Typography.Text
                      style={{ color: '#E53942', cursor: 'pointer' }}
                      onClick={() => showModal('analyze_error', record.id)}
                    >
                      <Tooltip title={t('aiSettingsPage.tooltips.analyzeError')}>
                        {errorData?.analyze_error || 0}
                      </Tooltip>
                    </Typography.Text>
                  </Col>
                  {/* Ошибка транскрибации */}
                  <Col>
                    <Typography.Text style={{ color: 'white' }}>
                      {t('aiSettingsPage.taskStatus.transcriptionError')}
                      {' - '}
                    </Typography.Text>
                    <Typography.Text
                      style={{ color: '#E53942', cursor: 'pointer' }}
                      onClick={() => showModal('transcription_error', record.id)}
                    >
                      <Tooltip title={t('aiSettingsPage.tooltips.transcriptionError')}>
                        {errorData?.transcription_error || 0}
                      </Tooltip>
                    </Typography.Text>
                  </Col>
                  {/* Превышен лимит анализа */}
                  <Col>
                    <Typography.Text style={{ color: 'white' }}>
                      {t('aiSettingsPage.taskStatus.analyzeLimitError')}
                      {' - '}
                    </Typography.Text>
                    <Typography.Text
                      style={{ color: '#E53942', cursor: 'pointer' }}
                      onClick={() => showModal('analyze_limit_error', record.id)}
                    >
                      <Tooltip
                        title={t('aiSettingsPage.tooltips.analyzeLimitError')}
                        placement="bottom"
                      >
                        {errorData?.analyze_limit_error || 0}
                      </Tooltip>
                    </Typography.Text>
                  </Col>
                  {/* Превышен лимит транскрибации */}
                  <Col>
                    <Typography.Text style={{ color: 'white' }}>
                      {t('aiSettingsPage.taskStatus.transcriptionLimitError')}
                      {' - '}
                    </Typography.Text>
                    <Typography.Text
                      style={{ color: '#E53942', cursor: 'pointer' }}
                      onClick={() => showModal('transcribation_limit_error', record.id)}
                    >
                      <Tooltip
                        title={t('aiSettingsPage.tooltips.transcriptionLimitError')}
                        placement="bottom"
                      >
                        {errorData?.transcribation_limit_error || 0}
                      </Tooltip>
                    </Typography.Text>
                  </Col>
                </Row>
              }
            >
              <SText style={{ color: '#E53942', cursor: 'pointer' }}>{resultErrorData}</SText>
            </Tooltip>
          );
        }
      },
      {
        title: 'Детализация ошибок',
        dataIndex: 'id',
        key: 'analyticsError',
        width: 200,
        align: 'center',
        render: (id, record) => {
          let errorData = null;
          let resultErrorData = 0;
          if (record?.analytics) {
            errorData = JSON.parse(record?.analytics);
            resultErrorData =
              (errorData?.analyze_error || 0) +
              (errorData?.validation_error || 0) +
              (errorData?.transcription_error || 0) +
              (errorData?.analyze_limit_error || 0) +
              (errorData?.transcribation_limit_error || 0);
          }

          return resultErrorData > 0 ? (
            <FileSearchOutlined onClick={() => showModalAnalyticsError(id)} />
          ) : null;
        }
      },
      {
        title: t('aiSettingsPage.tableColumnsTitle.taskId'),
        dataIndex: 'id',
        key: 'id',
        width: 40,
        align: 'center',
        render: id => {
          return (
            <CopyToClipboard
              text={id}
              onCopy={() => message.success(t('aiSettingsPage.messages.copyTaskIdSuccess'))}
            >
              <Tooltip title={t('aiSettingsPage.tooltips.copyTaskId')} placement="left">
                <CopyOutlined />
              </Tooltip>
            </CopyToClipboard>
          );
        }
      }
    ]
  };
  return (
    <SCol>
      <SCard bordered shadowed>
        <Row
          type="flex"
          align="start"
          justify="space-between"
          gutter={[8, 8]}
          style={{ margin: '-4px -4px 4px' }}
        />
        <STable
          dataSource={customFields}
          rowKey="id"
          size="small"
          loading={loading}
          columns={tableConfig.columns}
          border
          pagination={{
            pageSize: parseInt(page.size),
            current: parseInt(page.number),
            total: totalCount,
            showSizeChanger: true,
            pageSizeOptions: ['10', '25', '50']
          }}
          onChange={onPaginationChange}
          scroll={{ y: 'calc(100vh - 400px)' }}
        />
      </SCard>
      {!newTaskModal && (
        <ModalTask
          isModalOpen={isModalOpenAddorEditTask}
          setIsModalOpen={setIsModalOpenAddorEditTask}
          selectedRows={editingData}
          isEditingData={isEditingData}
          setIsEditingData={setIsEditingData}
          newTaskModal={newTaskModal}
          setNewTaskModal={setNewTaskModal}
        />
      )}
      <ModalChecklistDefinitions
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        statusChecklistDefinitions={statusChecklistDefinitions}
        taskId={idChecklistDefinitions}
        organizationId={organizationId}
      />
      <ModalTaskAnalyticsError
        isOpen={isModalOpenAnalyticsError}
        setIsModalOpen={setIsModalOpenAnalyticsError}
        taskId={idChecklistDefinitions}
        organizationId={organizationId}
      />
    </SCol>
  );
};

const mapStateToProps = state => {
  const { loading: aiSettingsResourceLoading, byIds: customFieldsByIds } = state.aiSettingsResource;
  const { loading: usersLoading } = state.usersResource;
  const {
    loading: aiSettingsListLoading,
    totalCount,
    page,
    sort,
    search,
    status
  } = state.aiSettingsList;
  const loading = aiSettingsListLoading || aiSettingsResourceLoading || usersLoading;
  const customFields = Object.values(customFieldsByIds);

  return {
    loading,
    totalCount,
    page,
    sort,
    search,
    customFields,
    status
  };
};
const mapDispatchToProps = {
  loadAiSettings: operations.load,
  loadPrompts: promptsListOperations.load,
  updatePage: actions.updatePage,
  setSorting: actions.setSorting,
  setSearch: actions.setSearch,
  setStatus: actions.setStatus,
  loadUsers: usersResource.operations.load,
  loadSalePipelines: salePipelinesResource.operations.load,
  loadChecklistDefinitions: checklistDefinitionsResource.operations.loadWithInclude
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AiSettingsTable));
