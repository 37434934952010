import camelizeKeys from 'camelcase-keys-deep';
import api, { buildResourceApiRequest, handlerEndpoint, integrationDeleteEndpoint } from 'core/api';
import { processJsonApiCollection, processJsonApiObject } from 'core/jsonapi';
import parseOptionsForApi from 'core/utils/parseOptionsForApi';
import decamilize from 'decamelize-keys-deep';
import { apiUrl } from 'core/config';
import { get, head } from 'lodash';
import { ACTIONS } from './constants';
import createCustomOperations from './createCustomOperations';
import loadIncludedObjectsToRedux from './loadIncludedObjects';

const makeGetRequest = async (action, method, meth, data) => {
  const method_ = meth + method.charAt(0).toUpperCase() + method.slice(1);
  const bodyData = {
    method: method_,
    action,
    data
  };

  const requestOptions = { method: 'POST', body: JSON.stringify(bodyData) };
  let response = await fetch(handlerEndpoint, requestOptions);
  response = await response.json();
  response = processIntegrationNames(response);

  return response;
};

// integration/delete integration_delete
const makeDeleteRequest = async (action, data) => {
  // await fetch(integrationDeleteEndpoint, {
  //   method: 'POST',
  //   body: JSON.stringify({
  //     id: data.id
  //   })
  // });
};

const processIntegrationNames = response_ => {
  const temp = {};
  temp.data = [];
  if (response_.type === 'Integration::Crm::BitrixIntegration') {
    response_.integration_type = 'bitrix_crm';
  }
  for (const item of response_.data) {
    let item_;
    item_ = {
      ...item
    };
    if (item_.attributes.type === 'Integration::Crm::BitrixIntegration') {
      item_.attributes.integration_type = 'bitrix_crm';
    }
    if (item_.attributes.type === 'Integration::Telephony::CustomHttpIntegration') {
      item_.attributes.integration_type = 'custom_http_telephony';
    }
    if (item_.attributes.type === 'Integration::Crm::AmocrmIntegration') {
      item_.attributes.integration_type = 'amocrm';
    }
    if (item_.attributes.type === 'Integration::Crm::HelpDeskEddyIntegration') {
      item_.attributes.integration_type = 'help_desk_eddy';
    }
    if (item_.attributes.type === 'Integration::Telephony::SipuniIntegration') {
      item_.attributes.integration_type = 'sipuni';
    }
    if (item_.attributes.type === 'Integration::Telephony::BinotelIntegration') {
      item_.attributes.integration_type = 'binotel';
    }
    if (item_.attributes.type === 'Integration::Text::CustomHttpIntegration') {
      item_.attributes.integration_type = 'custom_http_text';
    }
    if (item_.attributes.type === 'Integration::Text::Usedesk::UsedeskIntegration') {
      item_.attributes.integration_type = 'usedesk';
    }
    if (item_.attributes.type === 'Integration::Crm::ZendeskIntegration') {
      item_.attributes.integration_type = 'zendesk';
    }
    if (item_.attributes.type === 'Integration::Telephony::InfinityIntegration') {
      item_.attributes.integration_type = 'infinity';
    }
    if (item_.attributes.type === 'Integration::Crm::BrightPatternIntegration') {
      item_.attributes.integration_type = 'bright_pattern';
    }
    if (item_.attributes.type === 'Integration::Text::Uis::UisIntegration') {
      item_.attributes.integration_type = 'uis';
    }
    if (item_.attributes.type === 'Integration::Crm::NaumenIntegration') {
      item_.attributes.integration_type = 'naumen';
    }
    if (item_.attributes.type === 'Integration::Text::EdnaIntegration') {
      item_.attributes.integration_type = 'edna';
    }
    if (item_.attributes.type === 'Integration::Crm::S2Integration') {
      item_.attributes.integration_type = 's2';
    }
    if (item_.attributes.type === 'Integration::crm::PyrusIntegration') {
      item_.attributes.integration_type = 'pyrus';
    }
    temp.data.push(item_);
  }

  return temp;
};

const resourceOperations = {
  load: ({ name, actions, reactors }) => {
    const makeApiRequest = buildResourceApiRequest({ name, ...ACTIONS.load });

    return requestOptions => {
      return async (dispatch, getState) => {
        dispatch(actions.loadStarted(requestOptions));
        try {
          let response = {};
          let resources;
          if (requestOptions?.type === 'library') {
            const url = `${apiUrl}/api/v3/libraries/get?folder_id=${requestOptions.folder_id}&page=${requestOptions.page}&organization_id=${requestOptions.organization_id}`;
            const response_ = await fetch(url);
            response.body = await response_.json();
            resources = processJsonApiCollection(response.body.data);
          } else {
            response = await makeApiRequest(parseOptionsForApi(requestOptions));
            resources = processJsonApiCollection(response.body.data);
          }

          loadIncludedObjectsToRedux({ objects: response.body.included, dispatch });
          dispatch(actions.loadSucceed(resources));
          if (reactors.onLoadSucceed) {
            await reactors.onLoadSucceed({
              ...requestOptions,
              actions,
              resources,
              dispatch,
              getState
            });
          }
          return { resources, meta: camelizeKeys(response.body.meta) };
        } catch (error) {
          console.log('error', error);
          if (error?.body?.detail) {
            return dispatch(actions.loadFailed(error.body.detail));
          }
          dispatch(actions.loadFailed(error.message));
        }
        // users = loadUsers();
      };
    };
  },

  loadById: ({ name, actions, reactors }) => {
    return requestOptions => {
      const makeApiRequest = buildResourceApiRequest({ name, ...ACTIONS.loadById });
      const exceptions = ['integrations'];

      return async (dispatch, getState) => {
        dispatch(actions.loadByIdStarted(requestOptions));
        // let integration_id = requestOptions?.id;
        try {
          let response;
          if (requestOptions?.chain !== true) {
            response = await makeApiRequest(requestOptions);
          } else {
            response = await api.getCommunicationChain({
              id: requestOptions.id,
              params: requestOptions
            });
          }
          const resource = processJsonApiObject(response.body.data);

          // let included;
          // if (response.hasOwnProperty('body') && response.body.hasOwnProperty('included')) {
          //   included = response.body.included;
          // } else if (response.hasOwnProperty('data') && response.data.hasOwnProperty('included')) {
          //   included = response.data.included;
          // } else {
          //   included = {};
          // }

          loadIncludedObjectsToRedux({ objects: response.body.included, dispatch });
          dispatch(actions.loadByIdSucceed(resource));

          if (reactors.onLoadByIdSucceed) {
            await reactors.onLoadByIdSucceed({
              ...requestOptions,
              resource,
              dispatch,
              getState,
              response
            });
          }
          return resource;
        } catch (error) {
          console.log(error);
          // !error.status
          //   ? document.location.replace('/not-found')
          //   : dispatch(actions.loadByIdFailed(error.message));
        }
      };
    };
  },

  create: ({ name, actions, reactors }) => {
    return requestOptions => {
      const makeApiRequest = buildResourceApiRequest({ name, ...ACTIONS.create });
      return async (dispatch, getState) => {
        dispatch(actions.createStarted(requestOptions));
        try {
          const response = await makeApiRequest(decamilize(requestOptions));
          const resource = processJsonApiObject(response.body.data);
          loadIncludedObjectsToRedux({ objects: response.body.included, dispatch });
          dispatch(actions.createSucceed(resource));
          if (reactors.onCreateSucceed) {
            await reactors.onCreateSucceed({ ...requestOptions, resource, dispatch, getState });
          }
          return resource;
        } catch (error) {
          if (error.message) {
            dispatch(actions.createFailed(camelizeKeys(error.message)));
          } else if (error?.body?.errors) {
            dispatch(
              actions.createFailed({
                errors: camelizeKeys(error.body.errors)
              })
            );
          } else if (name === 'userCustomFields' && error) {
            dispatch(
              actions.createFailed({
                errors: camelizeKeys({
                  message: error?.body?.message,
                  fields_limit: error?.body?.fields_limit
                })
              })
            );
          }
        }
      };
    };
  },

  updateById: ({ name, actions, reactors }) => {
    return requestOptions => {
      const makeApiRequest = buildResourceApiRequest({ name, ...ACTIONS.updateById });
      return async (dispatch, getState) => {
        dispatch(actions.updateByIdStarted(requestOptions));
        try {
          const { id, ...updateParams } = requestOptions;
          const decamilizeUpdateParams = decamilize(updateParams);
          let newParams;
          // для ключей кастомных полей исключаем применение метода decamilize
          if ('custom_field_filters' in decamilizeUpdateParams) {
            newParams = {
              ...decamilizeUpdateParams,
              custom_field_filters: {
                ...updateParams.customFieldFilters
              }
            };
          } else {
            newParams = decamilizeUpdateParams;
          }
          const response = await makeApiRequest({ id }, newParams);
          const resource = name === 'phoneCallsV3' ? '' : processJsonApiObject(response.body.data);
          const included = get(response, 'body.included', []);
          loadIncludedObjectsToRedux({ objects: included, dispatch });
          dispatch(actions.updateByIdSucceed(resource));
          if (reactors.onUpdateByIdSucceed) {
            await reactors.onUpdateByIdSucceed({
              ...requestOptions,
              resource,
              included,
              dispatch,
              getState,
              response
            });
          }
          return resource;
        } catch (error) {
          if (error?.body?.errors) {
            dispatch(
              actions.updateByIdFailed({
                id: requestOptions.id,
                error: camelizeKeys(head(error.body.errors))
              })
            );
          } else if (name === 'userCustomFields' && error) {
            dispatch(
              actions.createFailed({
                errors: camelizeKeys({
                  message: error?.body?.message,
                  fields_limit: error?.body?.fields_limit
                })
              })
            );
            dispatch(actions.updateByIdFailed({ id: requestOptions.id, error }));
          } else {
            dispatch(actions.updateByIdFailed({ id: requestOptions.id, error }));
          }

          return Promise.reject(error);
        }
      };
    };
  },

  deleteById: ({ name, actions, reactors }) => {
    return requestOptions => {
      const makeApiRequest = buildResourceApiRequest({ name, ...ACTIONS.deleteById });
      const exceptions = ['integrations'];

      return async (dispatch, getState) => {
        dispatch(actions.deleteByIdStarted(requestOptions));
        try {
          if (exceptions.includes(name)) {
            // await makeDeleteRequest(name, requestOptions);
            await makeApiRequest(requestOptions);
          } else {
            await makeApiRequest(requestOptions);
          }

          if (reactors.onDeleteByIdSucceed) {
            await reactors.onDeleteByIdSucceed({ ...requestOptions, dispatch, getState });
          }
          dispatch(actions.deleteByIdSucceed(requestOptions));
          return requestOptions;
        } catch (error) {
          console.log(error);
          dispatch(actions.deleteByIdFailed(error.message));
        }
      };
    };
  }
};

export const createResourceOperations = ({ name, actions, reactors, customOperations }) => {
  const allowedActionNames = Object.keys(ACTIONS);
  const operations = allowedActionNames.reduce((result, actionName) => {
    return {
      ...result,
      [actionName]: resourceOperations[actionName]({ name, actions, reactors })
    };
  }, {});
  return {
    ...operations,
    ...createCustomOperations({ resource: name, customOperations, actions })
  };
};
